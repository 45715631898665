import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: new URLSearchParams(location.search).get("lang") ?? "en",
    
    //broker menus start 
    brokerLogo: "",
    brokerName:"",
    //broker menus end 
  },

  getters: {

    getBrokerLogo(state){
      return state.brokerLogo
    },
    getBrokerName(state){
      return state.brokerName
    }
  },

  mutations: {
 
    setBrokerLogo(state,content){
      return state.brokerLogo = content
    },

    setBrokerName(state,content){
      return state.brokerName = content
    },
  },

  actions: {
 
    initBrokerLogo(context,val){
      context.commit('setBrokerLogo',val)
    },

    initBrokerName(context,val){
      context.commit('setBrokerName',val)
    },
  },
  modules: {
  }
})
