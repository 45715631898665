import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import iframeResize from 'iframe-resizer/js/iframeResizer';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VuePhoneNumberInput);

Vue.use(VueAxios, axios)

// iframe resize start
Vue.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => {
      iframeResize(value, el)

    })
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})
// iframe resize end




Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
