<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  updated() {
    // scrolls to top on every route change
    window.scrollTo(0, 0);
  },
};
</script>

<style>
@import "./assets/css/reset.css";
</style>