import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/lps/naga',
    name: 'naga',
    component: () => import(/* webpackChunkName: "naga" */ '../views/lps/naga')
  },

  {
    path: '/',
    name: 'broker-menus',
    component: () => import(/* webpackChunkName: "broker-menus" */ '../views/broker-menus/brokerMenu')
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/broker-menus/register')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
